import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";

const Scroller = () => {
  // useEffect(() => {
  //   const savedScrollPosition = localStorage.getItem("scrollPosition");
  //   setTimeout(() => {
  //     window.scrollTo(0, parseInt(savedScrollPosition));
  //   }, 5000);
  // }, []);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (location.pathname.length > 1) {
        const div = document.getElementById(location.pathname.substring(1));
        if (div) {
          div.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        const savedScrollPosition = localStorage.getItem("scrollPosition");
        window.scrollTo(0, parseInt(savedScrollPosition));
      }
    }, 5000);
  }, []);

  return <></>;
};

export default Scroller;
