import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { HOME_TITLE } from "../Pages/TitleText";

// Component for each section
const Section = ({ id, title, thresholdValue, children }) => {
  const sectionRef = useRef();

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           // document.title = title;
  //           // Update the title when the section is in view
  //           // if (id != "Home") {
  //           //   window.history.pushState(null, "", `${id}`);
  //           // } else {
  //           //   window.history.pushState(null, "", "Home");
  //           // }
  //         }
  //       });
  //     },
  //     {
  //       root: null, // viewport
  //       threshold: thresholdValue, // 50% of the section must be visible
  //     }
  //   );

  //   if (sectionRef.current) {
  //     observer.observe(sectionRef.current);
  //   }

  //   return () => {
  //     if (sectionRef.current) {
  //       observer.unobserve(sectionRef.current);
  //     }
  //   };
  // }, [title]);

  const location = useLocation();
  if (location.pathname.length > 1) {
    document.title = title;
  } else {
    document.title = HOME_TITLE;
  }

  return (
    <section id={id} ref={sectionRef}>
      {children}
    </section>
  );
};

export default Section;
