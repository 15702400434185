import React from "react";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
//import { FontContext } from "../App";
import SolutionBy from "../Components/SolutionBy";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";
import Section from "../Components/Section";
import { SOL_BY_RL_TITLE } from "./TitleText";

const SolutionByRL = () => {
  const [error, setError] = useState(null);
  const [roledata, setroledata] = useState([]);
  const fontValue = useFont();
  const font = fontValue?.font;

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/byrl?populate=*")
      .then(({ data }) => setroledata(data.data.attributes))
      .catch((error) => setError(error));
  }, []);

  return (
    <Page isLoading={fontValue?.loading}>
      <Section
        id="solution-by-your-role"
        title={SOL_BY_RL_TITLE}
        thresholdValue={0.5}
      >
        <SolutionBy
          font={font}
          solutionData={{
            SUHTX_01: roledata.BYRL_SUHTX_01,
            CATHTX_1: roledata.CATHTX_3,
            BY1_HDTX_01: roledata.BYRL1_HDTX_01,
            BY1_IMG_01A: roledata.BYRL1_IMG_01A?.data?.attributes?.url,
            BY1_IMG_01B: roledata.BYRL1_IMG_01B?.data?.attributes?.url,
            BY1_DTX_01A: roledata.BYRL1_DTX_01A,
            BY2_HDTX_01: roledata.BYRL2_HDTX_01,
            BY2_IMG_01A: roledata.BYRL2_IMG_01A?.data?.attributes?.url,
            BY2_IMG_01B: roledata.BYRL2_IMG_01B?.data?.attributes?.url,
            BY2_DTX_02A: roledata.BYRL2_DTX_01A,
            BY3_HDTX_01: roledata.BYRL3_HDTX_01,
            BY3_IMG_01A: roledata.BYRL3_IMG_01A?.data?.attributes?.url,
            BY3_IMG_01B: roledata.BYRL3_IMG_01B?.data?.attributes?.url,
            BY3_DTX_03A: roledata.BYRL3_DTX_01A,
            BY4_HDTX_01: roledata.BYRL4_HDTX_01,
            BY4_IMG_01A: roledata.BYRL4_IMG_01A?.data?.attributes?.url,
            BY4_IMG_01B: roledata.BYRL4_IMG_01B?.data?.attributes?.url,
            BY4_DTX_04A: roledata.BYRL4_DTX_01A,
          }}
          type={"RL"}
          anchor="/solution-by-your-role"
        />
      </Section>
    </Page>
  );
};

export default SolutionByRL;
