import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BlocksRenderer, BlocksContent } from "@strapi/blocks-react-renderer";
import axios from "axios";
import { useRef, useEffect, useState, useContext } from "react";
//import { FontContext } from "../App";
import { useNavigate, useParams } from "react-router-dom";
import { useFont } from "../../contexts/FontContext";
import Page from "../../Components/Page";
import Section from "../../Components/Section";
import { SOL_BY_PROD_TITLE } from "../TitleText";
import { useLocation } from "react-router-dom";
import HomeButton from "../../Components/HomeButton";

const SolutionByProd = () => {
  const [error, setError] = useState(null);
  const [proddata, setproddata] = useState([]);
  const fontValue = useFont();
  const font = fontValue?.font;
  const BYP1Carousel = useRef(null);
  const BYP2Carousel = useRef(null);
  const BYP3Carousel = useRef(null);
  const BYP4Carousel = useRef(null);
  const BYP5Carousel = useRef(null);
  const BYP6Carousel = useRef(null);
  let currentStatus = 1;

  let navigate = useNavigate();

  const location = useLocation();

  let showHomeButton = false;

  if (location.pathname.length > 1) showHomeButton = true;

  useEffect(() => {
    var intervalId = window.setInterval(function () {
      if (currentStatus == 1) {
        if (BYP1Carousel.current != undefined && BYP1Carousel.current != null) {
          if (BYP1Carousel.current.state.selectedItem == 0)
            BYP1Carousel.current.increment(1);
          else BYP1Carousel.current.increment(-1);
        }
      } else if (currentStatus == 2) {
        if (BYP2Carousel.current != undefined && BYP2Carousel.current != null) {
          if (BYP2Carousel.current.state.selectedItem == 0)
            BYP2Carousel.current.increment(1);
          else BYP2Carousel.current.increment(-1);
        }
      } else if (currentStatus == 3) {
        if (BYP3Carousel.current != undefined && BYP3Carousel.current != null) {
          if (BYP3Carousel.current.state.selectedItem == 0)
            BYP3Carousel.current.increment(1);
          else BYP3Carousel.current.increment(-1);
        }
      } else if (currentStatus == 4) {
        if (BYP4Carousel.current != undefined && BYP4Carousel.current != null) {
          if (BYP4Carousel.current.state.selectedItem == 0)
            BYP4Carousel.current.increment(1);
          else BYP4Carousel.current.increment(-1);
        }
      } else if (currentStatus == 5) {
        if (BYP5Carousel.current != undefined && BYP5Carousel.current != null) {
          if (BYP5Carousel.current.state.selectedItem == 0)
            BYP5Carousel.current.increment(1);
          else BYP5Carousel.current.increment(-1);
        }
      } else if (currentStatus == 6) {
        if (BYP6Carousel.current != undefined && BYP6Carousel.current != null) {
          if (BYP6Carousel.current.state.selectedItem == 0)
            BYP6Carousel.current.increment(1);
          else BYP6Carousel.current.increment(-1);
        }
      }

      currentStatus++;

      if (currentStatus == 5) currentStatus = 0;
    }, 7000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/bypd?populate=*")
      .then(({ data }) => setproddata(data.data.attributes))
      .catch((error) => setError(error));
  }, []);

  useEffect(() => {
    // Get the <meta> element for the description
    let metaDescription = document.querySelector("meta[name='description']");

    // If it doesn't exist, create one
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }

    // Set the content of the meta description
    if (showHomeButton == true)
      metaDescription.content = proddata.BYPD_SUHTX_01;
  }, [proddata]);

  return (
    <Page isLoading={fontValue?.loading}>
      <Section
        id="solution-by-product"
        title={SOL_BY_PROD_TITLE}
        thresholdValue={0.1}
      >
        <div key={proddata.BYPD_SUHTX_01}>
          <div className="max-h-[400px] bg-gray-300 lg:pt-2">
            <div className="flex justify-center pt-4 pb-5 pl-2 pr-2">
              <p className={`${font?.SUHTX} text-black text-center`}>
                {proddata.BYPD_SUHTX_01?.split(/\n/).map((line) => (
                  <span key={`SUHTX_01solutionBYPD${line}`}>
                    {line} <br />
                  </span>
                ))}
              </p>
            </div>
          </div>
          <div className=" w-full h-auto bg-[#ECEBEB]">
            <div className="lg:max-w-[1100px] m-auto  lg:py-6 py-2 px-2">
              <a href="/solution-by-product" className="cursor-default">
                <h1 className={`${font?.HTX} text-black `}>
                  {proddata.PCHTX_5?.split(/\n/).map((line) => (
                    <span key={`PCHTX_5solutionBYPD${line}`}>
                      {line} <br />
                    </span>
                  ))}
                </h1>
              </a>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4 pt-4">
                {/* BYIN1*/}
                <div
                  className="border shadow-lg  lg:hover:scale-105 duration-300 bg-[#FDFDFD] h-full"
                  // onClick={() => {
                  //   navigate("/product/newbos");
                  // }}
                >
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      proddata.BYPD1_HDTX_01?.data?.attributes?.url
                    }
                    alt=""
                    className="w-36 h-[57px] pt-2 pl-2"
                    loading="lazy"
                  />

                  <Carousel
                    showStatus={false}
                    autoPlay={false}
                    showIndicators={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    showArrows={false}
                    transitionTime={2000}
                    centerMode={false}
                    interval={3000}
                    stopOnHover={false}
                    ref={BYP1Carousel}
                    swipeable={false}
                  >
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD1_IMG_01A?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD1_IMG_01B?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                  </Carousel>
                  <div className=" justify-between px-2 py-4">
                    {proddata?.BYPD1_DTX_01 != undefined ||
                    proddata?.BYPD1_DTX_01 != null ? (
                      <div className="pt-2 pl-2">
                        <BlocksRenderer
                          content={proddata?.BYPD1_DTX_01}
                          blocks={{
                            paragraph: ({ children }) => (
                              <span className={`${font?.DTX} text-black `}>
                                {children}
                                <br />
                              </span>
                            ),
                            list: ({ children }) => (
                              <ul className="list-disc pl-6">
                                <span className={`${font?.DTX}`}>
                                  {children}
                                </span>
                              </ul>
                            ),
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* BYIN2*/}
                <div className="border shadow-lg  lg:hover:scale-105 duration-300 bg-[#FDFDFD] h-full">
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      proddata.BYPD2_HDTX_01?.data?.attributes?.url
                    }
                    alt=""
                    className="w-36 h-[57px]  pt-2 pl-2"
                  />
                  <Carousel
                    showStatus={false}
                    autoPlay={false}
                    showIndicators={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    showArrows={false}
                    transitionTime={2000}
                    centerMode={false}
                    interval={3000}
                    stopOnHover={false}
                    ref={BYP2Carousel}
                    swipeable={false}
                  >
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD2_IMG_01A?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD2_IMG_01B?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                  </Carousel>
                  <div className="flex justify-between px-2 py-4">
                    {proddata?.BYPD2_DTX_01 != undefined ||
                    proddata?.BYPD2_DTX_01 != null ? (
                      <div className="pt-2 pl-2">
                        <BlocksRenderer
                          content={proddata?.BYPD2_DTX_01}
                          blocks={{
                            paragraph: ({ children }) => (
                              <span className={`${font?.DTX} text-black `}>
                                {children}
                                <br />
                              </span>
                            ),
                            list: ({ children }) => (
                              <ul className="list-disc pl-6">
                                <span className={`${font?.DTX}`}>
                                  {children}
                                </span>
                              </ul>
                            ),
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* BYIN3*/}
                <div className="border shadow-lg  lg:hover:scale-105 duration-300 bg-[#FDFDFD] h-full">
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      proddata.BYPD3_HDTX_01?.data?.attributes?.url
                    }
                    alt=""
                    className="w-36 h-[57px]  pt-2 pl-2"
                  />
                  <Carousel
                    showStatus={false}
                    autoPlay={false}
                    showIndicators={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    showArrows={false}
                    transitionTime={2000}
                    centerMode={false}
                    interval={3000}
                    stopOnHover={false}
                    ref={BYP3Carousel}
                    swipeable={false}
                  >
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD3_IMG_01A?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD3_IMG_01B?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                  </Carousel>
                  <div className="flex justify-between px-2 py-4">
                    {proddata?.BYPD3_DTX_01 != undefined ||
                    proddata?.BYPD3_DTX_01 != null ? (
                      <div className="pt-2 pl-2">
                        <BlocksRenderer
                          content={proddata?.BYPD3_DTX_01}
                          blocks={{
                            paragraph: ({ children }) => (
                              <span className={`${font?.DTX} text-black `}>
                                {children}
                                <br />
                              </span>
                            ),
                            list: ({ children }) => (
                              <ul className="list-disc pl-6">
                                <span className={`${font?.DTX}`}>
                                  {children}
                                </span>
                              </ul>
                            ),
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* BYIN4*/}
                <div className="border shadow-lg  lg:hover:scale-105 duration-300 bg-[#FDFDFD] h-full">
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      proddata.BYPD4_HDTX_01?.data?.attributes?.url
                    }
                    alt=""
                    className="w-36 h-[57px]  pt-2 pl-2"
                    loading="lazy"
                  />
                  <Carousel
                    showStatus={false}
                    autoPlay={false}
                    showIndicators={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    showArrows={false}
                    transitionTime={2000}
                    centerMode={false}
                    interval={3000}
                    stopOnHover={false}
                    ref={BYP4Carousel}
                    swipeable={false}
                  >
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD4_IMG_01A?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD4_IMG_01B?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                  </Carousel>
                  <div className="flex justify-between px-2 py-4">
                    {proddata?.BYPD4_DTX_01 != undefined ||
                    proddata?.BYPD4_DTX_01 != null ? (
                      <div className="pt-2 pl-2">
                        <BlocksRenderer
                          content={proddata?.BYPD4_DTX_01}
                          blocks={{
                            paragraph: ({ children }) => (
                              <span className={`${font?.DTX} text-black `}>
                                {children}
                                <br />
                              </span>
                            ),
                            list: ({ children }) => (
                              <ul className="list-disc pl-6">
                                <span className={`${font?.DTX}`}>
                                  {children}
                                </span>
                              </ul>
                            ),
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* BYIN5*/}
                <div className="border shadow-lg  lg:hover:scale-105 duration-300 bg-[#FDFDFD] h-full">
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      proddata.BYPD5_HDTX_01?.data?.attributes?.url
                    }
                    alt=""
                    className="w-36 h-[57px]  pt-2 pl-2"
                    loading="lazy"
                  />
                  <Carousel
                    showStatus={false}
                    autoPlay={false}
                    showIndicators={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    showArrows={false}
                    transitionTime={2000}
                    centerMode={false}
                    interval={3000}
                    stopOnHover={false}
                    ref={BYP5Carousel}
                    swipeable={false}
                  >
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD5_IMG_01A?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD5_IMG_01B?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                  </Carousel>
                  <div className="flex justify-between px-2 py-4">
                    {proddata?.BYPD5_DTX_01 != undefined ||
                    proddata?.BYPD5_DTX_01 != null ? (
                      <div className="pt-2 pl-2">
                        <BlocksRenderer
                          content={proddata?.BYPD5_DTX_01}
                          blocks={{
                            paragraph: ({ children }) => (
                              <span className={`${font?.DTX} text-black `}>
                                {children}
                                <br />
                              </span>
                            ),
                            list: ({ children }) => (
                              <ul className="list-disc pl-6">
                                <span className={`${font?.DTX}`}>
                                  {children}
                                </span>
                              </ul>
                            ),
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* BYIN6*/}
                <div className="border shadow-lg  lg:hover:scale-105 duration-300 bg-[#FDFDFD] h-full">
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      proddata.BYPD6_HDTX_01?.data?.attributes?.url
                    }
                    alt=""
                    className="w-36 h-[57px]  pt-2 pl-2"
                    loading="lazy"
                  />
                  <Carousel
                    showStatus={false}
                    autoPlay={false}
                    showIndicators={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    showArrows={false}
                    transitionTime={2000}
                    centerMode={false}
                    interval={3000}
                    stopOnHover={false}
                    ref={BYP6Carousel}
                    swipeable={false}
                  >
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD6_IMG_01A?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        proddata.BYPD6_IMG_01B?.data?.attributes?.url
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                  </Carousel>
                  <div className="flex justify-between px-2 py-4">
                    {proddata?.BYPD6_DTX_01 != undefined ||
                    proddata?.BYPD6_DTX_01 != null ? (
                      <div className="pt-2 pl-2">
                        <BlocksRenderer
                          content={proddata?.BYPD6_DTX_01}
                          blocks={{
                            paragraph: ({ children }) => (
                              <span className={`${font?.DTX} text-black `}>
                                {children}
                                <br />
                              </span>
                            ),
                            list: ({ children }) => (
                              <ul className="list-disc pl-6">
                                <span className={`${font?.DTX}`}>
                                  {children}
                                </span>
                              </ul>
                            ),
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {showHomeButton == true ? <HomeButton /> : ""}
    </Page>
  );
};

export default SolutionByProd;
