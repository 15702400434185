import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Main from "./Main";

import SolutionByIndustry from "./SolutionByIndustry";
import SolutionByBM from "./SolutionByBM";
import SolutionByRL from "./SolutionByRL";
import SolutionByGoal from "./SolutionByGoal";
import SolutionByProd from "./Product/SolutionByProd";
import ScheduleDemo from "./ScheduleDemo";
import SolutionByMDF from "./SolutionByMDF";
import SolutionByTCH from "./SolutionByTCH";
import SolutionByENT from "./SolutionByENT";
import { useNavigate, useParams } from "react-router-dom";
import Scroller from "./Scroller";
import {
  SOL_BY_IND_TITLE,
  SOL_BY_BM_TITLE,
  SOL_BY_RL_TITLE,
  SOL_BY_GL_TITLE,
  SOL_BY_PROD_TITLE,
  SHD_A_DEMO_TITLE,
} from "./TitleText";

const Home = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const location = useLocation();

  if (location.pathname.length > 1) {
    let path = location.pathname.substring(1);

    if (path == "Solution-by-your-Industry") {
      document.title = SOL_BY_IND_TITLE;
    } else if (path == "Solution-by-Business-Model") {
      document.title = SOL_BY_BM_TITLE;
    } else if (path == "Solution-by-your-Role") {
      document.title = SOL_BY_RL_TITLE;
    } else if (path == "Solution-by-Your-Goals-&-Needs") {
      document.title = SOL_BY_GL_TITLE;
    } else if (path == "Solution-by-Product") {
      document.title = SOL_BY_PROD_TITLE;
    } else if (path == "Schedule-a-Demo") {
      document.title = SHD_A_DEMO_TITLE;
    }
  }

  // Save scroll position to localStorage when component unmounts
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Save scroll position to localStorage before page reloads
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("scrollPosition", scrollPosition);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [scrollPosition]);

  return (
    <div>
      <Main />
      <SolutionByIndustry />
      <SolutionByBM />
      <SolutionByRL />
      <SolutionByGoal />
      <SolutionByProd />
      {/* <SolutionByMDF />
      <SolutionByTCH />
      <SolutionByENT /> */}
      <ScheduleDemo />
      <Scroller />
    </div>
  );
};

export default Home;
