import React, { useRef, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { useLocation } from "react-router-dom";
import HomeButton from "./HomeButton";

export const SolutionBy = ({ font, solutionData, type, anchor }) => {
  // console.log(solutionData);
  const BY1Carousel = useRef(null);
  const BY2Carousel = useRef(null);
  const BY3Carousel = useRef(null);
  const BY4Carousel = useRef(null);

  let currentStatus = 1;

  const location = useLocation();

  let showHomeButton = false;

  if (location.pathname.length > 1) showHomeButton = true;

  useEffect(() => {
    var intervalId = window.setInterval(function () {
      if (currentStatus == 1) {
        if (BY1Carousel.current !== undefined && BY1Carousel.current !== null) {
          if (BY1Carousel.current.state.selectedItem == 0)
            BY1Carousel.current.increment(1);
          else BY1Carousel.current.increment(-1);
        }
      } else if (currentStatus == 2) {
        if (BY2Carousel.current !== undefined && BY2Carousel.current !== null) {
          if (BY2Carousel.current.state.selectedItem == 0)
            BY2Carousel.current.increment(1);
          else BY2Carousel.current.increment(-1);
        }
      } else if (currentStatus == 3) {
        if (BY3Carousel.current !== undefined && BY3Carousel.current !== null) {
          if (BY3Carousel.current.state.selectedItem == 0)
            BY3Carousel.current.increment(1);
          else BY3Carousel.current.increment(-1);
        }
      } else if (currentStatus == 4) {
        if (BY4Carousel.current !== undefined && BY4Carousel.current !== null) {
          if (BY4Carousel.current.state.selectedItem == 0)
            BY4Carousel.current.increment(1);
          else BY4Carousel.current.increment(-1);
        }
      }

      currentStatus++;

      if (currentStatus == 5) currentStatus = 0;
    }, 7000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Get the <meta> element for the description
    let metaDescription = document.querySelector("meta[name='description']");

    // If it doesn't exist, create one
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }

    // Set the content of the meta description
    if (showHomeButton == true) metaDescription.content = solutionData.SUHTX_01;
  }, [solutionData]);

  return (
    <>
      <div key={solutionData.CATHTX_1}>
        <div className="max-h-[400px] bg-gray-300 lg:pt-2">
          <div className="flex justify-center pt-4 pb-5 pl-2 pr-2">
            <p className={`${font?.SUHTX} text-black text-center`}>
              {solutionData.SUHTX_01?.split(/\n/).map((line) => (
                <span key={`SUHTX_01${type}${line}`}>
                  {line} <br />
                </span>
              ))}
            </p>
          </div>
        </div>
        <div className=" justify-center w-full h-auto bg-[#ECEBEB]">
          <div className="lg:max-w-[1100px] m-auto lg:py-6 py-2 px-2">
            <a href={`${anchor}`}>
              <h1 className={`${font?.HTX} text-black `}>
                {solutionData.CATHTX_1?.split(/\n/).map((line) => (
                  <span key={`CATHTX_1${type}${line}`}>
                    {line} <br />
                  </span>
                ))}{" "}
              </h1>
            </a>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4 pt-4">
              {/* BYIN1*/}
              <div className="border shadow-lg  lg:hover:scale-105 duration-300 bg-[#FDFDFD] h-full min-w-fit ">
                <p className=" pt-2 pl-2 h-14">
                  {solutionData.BY1_HDTX_01?.split(/\n/).map((line) => (
                    <span
                      key={`BY1_HDTX_01${type}${line}`}
                      className={`${font?.HDTX} text-black leading-3`}
                    >
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
                <Carousel
                  showStatus={false}
                  autoPlay={false}
                  showIndicators={false}
                  showThumbs={false}
                  infiniteLoop={true}
                  showArrows={false}
                  transitionTime={2000}
                  centerMode={false}
                  interval={3000}
                  stopOnHover={false}
                  ref={BY1Carousel}
                  swipeable={false}
                >
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      solutionData.BY1_IMG_01A
                    }
                    alt=""
                    className="w-full h-[200px] object-cover pt-2"
                    loading="lazy"
                  />
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      solutionData.BY1_IMG_01B
                    }
                    alt=""
                    className="w-full h-[200px] object-cover pt-2"
                    loading="lazy"
                  />
                </Carousel>
                <div className=" justify-between px-2 py-4">
                  {solutionData?.BY1_DTX_01A != undefined ||
                  solutionData?.BY1_DTX_01A != null ? (
                    <div className="pt-2 pl-2">
                      <BlocksRenderer
                        content={solutionData?.BY1_DTX_01A}
                        blocks={{
                          paragraph: ({ children }) => (
                            <span className={`${font?.DTX} text-black `}>
                              {children}
                              <br />
                            </span>
                          ),
                          list: ({ children }) => (
                            <ul className="list-disc pl-6">
                              <span className={`${font?.DTX}`}>{children}</span>
                            </ul>
                          ),
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* BYIN2*/}
              <div className="border shadow-lg  lg:hover:scale-105 duration-300 bg-[#FDFDFD] h-full min-w-72">
                <p className=" pt-2 pl-2 h-14">
                  {solutionData.BY2_HDTX_01?.split(/\n/).map((line) => (
                    <span
                      key={`BY2_HDTX_01${type}${line}`}
                      className={`${font?.HDTX} text-black leading-3`}
                    >
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
                <Carousel
                  showStatus={false}
                  autoPlay={false}
                  showIndicators={false}
                  showThumbs={false}
                  infiniteLoop={true}
                  showArrows={false}
                  transitionTime={2000}
                  centerMode={false}
                  interval={3000}
                  stopOnHover={false}
                  ref={BY2Carousel}
                  swipeable={false}
                >
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      solutionData.BY2_IMG_01A
                    }
                    alt=""
                    className="w-full h-[200px] object-cover pt-2"
                    loading="lazy"
                  />
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      solutionData.BY2_IMG_01B
                    }
                    alt=""
                    className="w-full h-[200px] object-cover pt-2"
                    loading="lazy"
                  />
                </Carousel>
                <div className="flex justify-between px-2 py-4">
                  {solutionData?.BY2_DTX_02A != undefined ||
                  solutionData?.BY2_DTX_02A != null ? (
                    <div className="pt-2 pl-2">
                      <BlocksRenderer
                        content={solutionData?.BY2_DTX_02A}
                        blocks={{
                          paragraph: ({ children }) => (
                            <span className={`${font?.DTX} text-black `}>
                              {children}
                              <br />
                            </span>
                          ),
                          list: ({ children }) => (
                            <ul className="list-disc pl-6">
                              <span className={`${font?.DTX}`}>{children}</span>
                            </ul>
                          ),
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* BYIN3*/}
              <div className="border shadow-lg  lg:hover:scale-105 duration-300 bg-[#FDFDFD] h-full min-w-72">
                <p className="pt-2 pl-2 h-14">
                  {solutionData.BY3_HDTX_01?.split(/\n/).map((line) => (
                    <span
                      key={`BY3_HDTX_01${type}${line}`}
                      className={`${font?.HDTX} text-black`}
                    >
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
                <Carousel
                  showStatus={false}
                  autoPlay={false}
                  showIndicators={false}
                  showThumbs={false}
                  infiniteLoop={true}
                  showArrows={false}
                  transitionTime={2000}
                  centerMode={false}
                  interval={3000}
                  stopOnHover={false}
                  ref={BY3Carousel}
                  swipeable={false}
                >
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      solutionData.BY3_IMG_01A
                    }
                    alt=""
                    className="w-full h-[200px] object-cover pt-2"
                    loading="lazy"
                  />
                  <img
                    src={
                      process.env.REACT_APP_STRAPI_API_URL +
                      solutionData.BY3_IMG_01B
                    }
                    alt=""
                    className="w-full h-[200px] object-cover pt-2"
                    loading="lazy"
                  />
                </Carousel>
                <div className="flex justify-between px-2 py-4">
                  {solutionData?.BY3_DTX_03A != undefined ||
                  solutionData?.BY3_DTX_03A != null ? (
                    <div className="pt-2 pl-2">
                      <BlocksRenderer
                        content={solutionData?.BY3_DTX_03A}
                        blocks={{
                          paragraph: ({ children }) => (
                            <span className={`${font?.DTX} text-black `}>
                              {children}
                              <br />
                            </span>
                          ),
                          list: ({ children }) => (
                            <ul className="list-disc pl-6">
                              <span className={`${font?.DTX}`}>{children}</span>
                            </ul>
                          ),
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* BYIN4*/}
              {solutionData.BY4_HDTX_01 ? (
                <div className="border shadow-lg  lg:hover:scale-105 duration-300 bg-[#FDFDFD] h-full min-w-72">
                  <p className="pt-2 pl-2 h-14">
                    {solutionData.BY4_HDTX_01?.split(/\n/).map((line) => (
                      <span
                        key={`BY4_HDTX_01${type}${line}`}
                        className={`${font?.HDTX} text-black`}
                      >
                        {line}
                        <br />
                      </span>
                    ))}
                  </p>
                  <Carousel
                    showStatus={false}
                    autoPlay={false}
                    showIndicators={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    showArrows={false}
                    transitionTime={2000}
                    centerMode={false}
                    interval={3000}
                    stopOnHover={false}
                    ref={BY4Carousel}
                    swipeable={false}
                  >
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        solutionData.BY4_IMG_01A
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        solutionData.BY4_IMG_01B
                      }
                      alt=""
                      className="w-full h-[200px] object-cover pt-2"
                      loading="lazy"
                    />
                  </Carousel>
                  <div className="flex justify-between px-2 py-4">
                    {solutionData?.BY4_DTX_04A != undefined ||
                    solutionData?.BY4_DTX_04A != null ? (
                      <div className="pt-2 pl-2">
                        <BlocksRenderer
                          content={solutionData?.BY4_DTX_04A}
                          blocks={{
                            paragraph: ({ children }) => (
                              <span className={`${font?.DTX} text-black `}>
                                {children}
                                <br />
                              </span>
                            ),
                            list: ({ children }) => (
                              <ul className="list-disc pl-6">
                                <span className={`${font?.DTX}`}>
                                  {children}
                                </span>
                              </ul>
                            ),
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {showHomeButton == true ? <HomeButton /> : ""}
    </>
  );
};

export default SolutionBy;
