import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";
import HomeButton from "../Components/HomeButton";
import { BlocksRenderer, BlocksContent } from "@strapi/blocks-react-renderer";
import { DATA_PRIVACY_TITLE } from "./TitleText";

const DataPrivacyPolicy = () => {
  const fontValue = useFont();
  const font = fontValue?.font;

  const [error, setError] = useState(null);
  const [privacyData, setprivacyData] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/dapr?populate=*")
      .then(({ data }) => {
        setprivacyData(data.data);
      })
      .catch((error) => setError(error));
  }, []);

  useEffect(() => {
    document.title = DATA_PRIVACY_TITLE;

    // Get the <meta> element for the description
    let metaDescription = document.querySelector("meta[name='description']");

    // If it doesn't exist, create one
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }

    // Set the content of the meta description
    metaDescription.content = `NewBOS Partners, LLC (hereinafter “NewBOS”) is committed to maintaining the accuracy, confidentiality,
and security of your personally identifiable information (hereinafter "Personal Information"). As part of this
commitment, our privacy policy governs all of our actions as they relate to the collection, use, and
disclosure of all Personal Information.`;
  }, [privacyData]);

  return (
    <Page isLoading={fontValue?.loading}>
      <div className="bg-gray-300 ">
        <div className="lg:max-w-[1100px] m-auto ">
          <div className="flex justify-center pt-10 pb-5">
            <h1 className={`${font?.SUHTX} text-black text-center`}>
              {privacyData.attributes?.DAPRHTX_1}
            </h1>
          </div>

          <div className="flex justify-center pt-3 pb-5">
            <div className="lg:max-w-[1100px] m-auto  lg:py-6 py-2  lg:pt-2 ">
              <div className=" justify-start pt-2 pb-5">
                {privacyData.attributes?.DAPRDTX_1 != undefined ||
                privacyData.attributes?.DAPRDTX_1 != null ? (
                  <div className="pt-2 pl-2 pr-2">
                    <BlocksRenderer
                      content={privacyData.attributes?.DAPRDTX_1}
                      blocks={{
                        paragraph: ({ children }) => (
                          <span className={`${font?.DAPR_DTXT} text-black `}>
                            {children}
                            <br />
                          </span>
                        ),
                        list: ({ children }) => (
                          <ul className="list-disc pl-6">
                            <span className={`${font?.DAPR_DTXT}`}>
                              {children}
                            </span>
                          </ul>
                        ),
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeButton />
    </Page>
  );
};

export default DataPrivacyPolicy;
