import React from "react";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
//import { FontContext } from "../App";
import SolutionBy from "../Components/SolutionBy";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";
import Section from "../Components/Section";
import { SOL_BY_GL_TITLE } from "./TitleText";

const SolutionByGoal = () => {
  const [error, setError] = useState(null);
  const [goaldata, setgoaldata] = useState([]);
  const fontValue = useFont();
  const font = fontValue?.font;

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/byyg?populate=*")
      .then(({ data }) => setgoaldata(data.data.attributes))
      .catch((error) => setError(error));
  }, []);

  return (
    <Page isLoading={fontValue?.loading}>
      <Section
        id="solution-by-your-goals-and-needs"
        title={SOL_BY_GL_TITLE}
        thresholdValue={0.5}
      >
        <SolutionBy
          font={font}
          solutionData={{
            SUHTX_01: goaldata.BYYG_SUHTX_01,
            CATHTX_1: goaldata.CATHTX_4,
            BY1_HDTX_01: goaldata.BYYG1_HDTX_01,
            BY1_IMG_01A: goaldata.BYYG1_IMG_01A?.data?.attributes?.url,
            BY1_IMG_01B: goaldata.BYYG1_IMG_01B?.data?.attributes?.url,
            BY1_DTX_01A: goaldata.BYYG1_DTX_01A,
            BY2_HDTX_01: goaldata.BYYG2_HDTX_01,
            BY2_IMG_01A: goaldata.BYYG2_IMG_01A?.data?.attributes?.url,
            BY2_IMG_01B: goaldata.BYYG2_IMG_01B?.data?.attributes?.url,
            BY2_DTX_02A: goaldata.BYYG2_DTX_01A,
            BY3_HDTX_01: goaldata.BYYG3_HDTX_01,
            BY3_IMG_01A: goaldata.BYYG3_IMG_01A?.data?.attributes?.url,
            BY3_IMG_01B: goaldata.BYYG3_IMG_01B?.data?.attributes?.url,
            BY3_DTX_03A: goaldata.BYYG3_DTX_01A,
            BY4_HDTX_01: goaldata.BYYG4_HDTX_01,
            BY4_IMG_01A: goaldata.BYYG4_IMG_01A?.data?.attributes?.url,
            BY4_IMG_01B: goaldata.BYYG4_IMG_01B?.data?.attributes?.url,
            BY4_DTX_04A: goaldata.BYYG4_DTX_01A,
          }}
          type={"GL"}
          anchor="/solution-by-your-goals-and-needs"
        />
      </Section>
    </Page>
  );
};

export default SolutionByGoal;
