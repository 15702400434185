import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFont } from "../contexts/FontContext";
import axios from "axios";
import Page from "../Components/Page";

const BottomBar = () => {
  const [error, setError] = useState(null);
  const [bottomData, setBottomData] = useState([]);
  let navigate = useNavigate();
  const fontValue = useFont();
  const font = fontValue?.font;

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/BNB?populate=*")
      .then(({ data }) => {
        setBottomData(data.data);
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <Page isLoading={fontValue?.loading}>
      <div className="top-0 z-10 w-full bg-[#161C30] opacity-50 max-h-60 min-h-[65px]">
        <div className="lg:max-w-[1100px] m-auto ">
          <div className="flex items-start pt-5 pb-5">
            {bottomData.attributes?.BNBTX_1 != null ? (
              <a href="/" className="lg:pl-2 pl-5">
                <span className={`font-raleway font-bold text-sm text-white`}>
                  {bottomData.attributes?.BNBTX_1}
                </span>
              </a>
            ) : (
              ""
            )}
            {/* {bottomData.attributes?.BNBTX_2 != null ? (
              <a href="/contactus" className="lg:pl-10 pl-5">
                <span className={`font-raleway font-bold text-sm text-white`}>
                  {bottomData.attributes?.BNBTX_2}
                </span>
              </a>
            ) : (
              ""
            )} */}
            {bottomData.attributes?.BNBTX_3 != null ? (
              <a href="/contactus" className="lg:pl-10 pl-5">
                <span className={`font-raleway font-bold text-sm text-white`}>
                  {bottomData.attributes?.BNBTX_3}
                </span>
              </a>
            ) : (
              ""
            )}
            {bottomData.attributes?.BNBTX_4 != null ? (
              <a href="/data-privacy-policy" className="lg:pl-10 pl-5">
                <span className={`font-raleway font-bold text-sm text-white`}>
                  {bottomData.attributes?.BNBTX_4}
                </span>
              </a>
            ) : (
              ""
            )}
            {bottomData.attributes?.BNBTX_5 != null ? (
              <a href="/end-user-license-agreement" className="lg:pl-10 pl-5">
                <span className={`font-raleway font-bold text-sm text-white`}>
                  {bottomData.attributes?.BNBTX_5}
                </span>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default BottomBar;
