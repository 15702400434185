export const HOME_TITLE =
  "NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const SOL_BY_IND_TITLE =
  "Solution by your Industry | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const SOL_BY_BM_TITLE =
  "Solution by Business Model | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const SOL_BY_RL_TITLE =
  "Solution by your Role | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const SOL_BY_GL_TITLE =
  "Solution by Your Goals & Needs | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const SOL_BY_PROD_TITLE =
  "Solution by Product | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const SHD_A_DEMO_TITLE =
  "Schedule a Demo | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const CONTACT_US_TITLE =
  "Contact Us | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const DATA_PRIVACY_TITLE =
  "NewBOS User Data Privacy Policy | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const DIGITAL_LIBRARY_TITLE =
  "Download Assets & Digital Library | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const EULA_TITLE =
  "End User License Agreement | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
export const MEET_SHARE_TITLE =
  "Meet & Share | NewBOS Partners, LLC™: Enterprise Resource Planning Software Suite.";
