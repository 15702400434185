import React, { createContext, useEffect, useState } from "react";
import { Route, Routes, Switch } from "react-router-dom";
import axios from "axios";
import TopBar from "./Components/TopBar";
import Home from "./Pages/Home";
import NewbosProduct from "./Pages/Product/NewbosProduct";
import MeetAndShare from "./Pages/MeetAndShare";
import Layout from "./Components/Layout";
import DataPrivacyPolicy from "./Pages/DataPrivacyPolicy";
import ContactUs from "./Pages/ContactUs";
import EULA from "./Pages/EULA";
import DigitalLibrary from "./Pages/DigitalLibrary";
import TextMessageAuth from "./Pages/TextMessageAuth";
import SolutionByIndustry from "./Pages/SolutionByIndustry";
import SolutionByBM from "./Pages/SolutionByBM";
import SolutionByRL from "./Pages/SolutionByRL";
import SolutionByGoal from "./Pages/SolutionByGoal";
import SolutionByProd from "./Pages/Product/SolutionByProd";
import NotFound from "./NotFound";

function App() {
  return (
    <div className="App">
      <Layout>
        {/* This component handles the scroll */}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="/solution-by-your-industry"
            element={<SolutionByIndustry />}
          />
          <Route
            path="/solution-by-business-model"
            element={<SolutionByBM />}
          />
          <Route path="/solution-by-your-role" element={<SolutionByRL />} />
          <Route
            path="/solution-by-your-goals-and-needs"
            element={<SolutionByGoal />}
          />
          <Route path="/solution-by-product" element={<SolutionByProd />} />
          {/* <Route
            path="/product/:productname"
            element={<NewbosProduct />}
          ></Route> */}
          <Route path="/meet-and-share" element={<MeetAndShare />}></Route>
          <Route
            path="/data-privacy-policy"
            element={<DataPrivacyPolicy />}
          ></Route>
          {/* <Route path="/contactus" element={<ContactUs />}></Route> */}
          <Route path="/end-user-license-agreement" element={<EULA />}></Route>
          <Route path="/digital-library" element={<DigitalLibrary />}></Route>
          <Route path="/textmessageauth" element={<TextMessageAuth />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
