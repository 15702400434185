import React from "react";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
//import { FontContext } from "../App";
import SolutionBy from "../Components/SolutionBy";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";
import Section from "../Components/Section";
import { SOL_BY_IND_TITLE } from "./TitleText";

const SolutionByIndustry = () => {
  const [error, setError] = useState(null);
  const [industrydata, setIndustryData] = useState([]);
  const fontValue = useFont();
  const font = fontValue?.font;

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/byin?populate=*")
      .then(({ data }) => setIndustryData(data.data.attributes))
      .catch((error) => setError(error));
  }, []);

  return (
    <Page isLoading={fontValue?.loading}>
      <Section
        id="solution-by-your-industry"
        title={SOL_BY_IND_TITLE}
        thresholdValue={0.5}
      >
        <SolutionBy
          font={font}
          solutionData={{
            SUHTX_01: industrydata.BYIN_SUHTX_01,
            CATHTX_1: industrydata.CATHTX_1,
            BY1_HDTX_01: industrydata.BYIN1_HDTX_01,
            BY1_IMG_01A: industrydata.BYIN1_IMG_01A?.data?.attributes?.url,
            BY1_IMG_01B: industrydata.BYIN1_IMG_01B?.data?.attributes?.url,
            BY1_DTX_01A: industrydata.BYIN1_DTX_01A,
            BY2_HDTX_01: industrydata.BYIN2_HDTX_01,
            BY2_IMG_01A: industrydata.BYIN2_IMG_01A?.data?.attributes?.url,
            BY2_IMG_01B: industrydata.BYIN2_IMG_01B?.data?.attributes?.url,
            BY2_DTX_02A: industrydata.BYIN2_DTX_02A,
            BY3_HDTX_01: industrydata.BYIN3_HDTX_01,
            BY3_IMG_01A: industrydata.BYIN3_IMG_01A?.data?.attributes?.url,
            BY3_IMG_01B: industrydata.BYIN3_IMG_01B?.data?.attributes?.url,
            BY3_DTX_03A: industrydata.BYIN3_DTX_03A,
            BY4_HDTX_01: industrydata.BYIN4_HDTX_01,
            BY4_IMG_01A: industrydata.BYIN4_IMG_01A?.data?.attributes?.url,
            BY4_IMG_01B: industrydata.BYIN4_IMG_01B?.data?.attributes?.url,
            BY4_DTX_04A: industrydata.BYIN4_DTX_04A,
          }}
          type={"IND"}
          anchor="/solution-by-your-industry"
        />
      </Section>
    </Page>
  );
};

export default SolutionByIndustry;
