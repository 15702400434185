import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import HomeButton from "./Components/HomeButton";
import { Helmet, HelmetProvider } from "react-helmet-async";

const NotFound = () => {
  useEffect(() => {
    // Get the <meta> element for the description
    let metaDescription = document.querySelector("meta[name='description']");

    // If it doesn't exist, create one
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    // Set the content of the meta description
    metaDescription.content = `"The page you are looking for does not exist.`;
  }, []);

  return (
    <div style={styles.container} className="h-screen">
      <HelmetProvider>
        {/* Other components */}
        <Helmet>
          <title>404 - Page Not Found</title>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
      </HelmetProvider>
      <h1 style={styles.header}>404 - Page Not Found</h1>
      <p style={styles.text}>
        Sorry, the page you are looking for does not exist.
      </p>
      <Link to="/" style={styles.link}>
        Go to newbos.com
      </Link>
      <HomeButton />
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "50px",
  },
  header: {
    fontSize: "2em",
    marginBottom: "20px",
  },
  text: {
    fontSize: "1.2em",
    marginBottom: "20px",
  },
  link: {
    color: "#007BFF",
    textDecoration: "none",
  },
};

export default NotFound;
